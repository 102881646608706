/* eslint-disable */
import React from 'react'
import Helmet from '../../components/Helmet'

import { graphql } from 'gatsby'

import CoupleLookingOutWindow from '../../images/heroes/couple-looking-out-window.jpg'
import CoupleLookingOutWindowMobile from '../../images/heroes/couple-looking-out-window-mobile.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  Column,
  Columns,
  Link,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'
import isCallCenterOpen from '../../../../../utils/isOpen'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-medicareadvantagelp']

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title: 'Medicare Special Needs Plans (SNPs) | HealthcarePlans.com',
          description:
            'Medicare Special Needs Plan (SNP) is a type of Medicare Advantage plan available to those with specific qualifying conditions. Explore your SNP options today.',
          canonical: 'https://healthcareplans.com/plans/snp',
          robots: 'follow,index',
        },
        path: '/plans/snp',
        promoCode: '170330',
        //   {
        //     shortName: 'Home',
        //     path: '/',
        //   },
        //   {
        //     shortName: 'Plans',
        //     path: '/plans',
        //   },
        // ],
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              mobileAlignImageToBottom
              image={
                <img
                  src={CoupleLookingOutWindow}
                  alt="an elderly married couple looks out of kitchen window and smiles"
                  className="married-couple-beach-hero"
                />
              }
              mobileImage={
                <img
                  src={CoupleLookingOutWindowMobile}
                  alt="an elderly married couple looks out of kitchen window and smiles"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Explore Medicare Special Needs Plans (SNPs)
                  </Typography>
                  <Typography variant="h4">
                    Read on for help finding the right SNP for you, including
                    Dual Eligible Special Needs Plans from Healthcareplans.com.
                  </Typography>
                  <Typography variant="body">
                    Medicare Special Needs Plans (SNPs) offer the same Medicare
                    Part A & B coverage that most Medicare Advantage plans
                    cover, but are only available to those who meet certain
                    special qualifications. SNPs may also offer additional
                    benefits.
                  </Typography>

                  {isCallCenterOpen() ? (
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className=""
                      requestId={requestId}
                    >
                      Call Now {rotatedNumber}
                    </LinkButton>
                  ) : (
                    <AvailabilityForm
                      placeholderText="Zip Code"
                      buttonText="Find a Medicare D-SNP"
                      buttonColor="primary"
                      butttonBackgroundColor="black"
                      className="avail-form"
                    />
                  )}
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos header"
          mainContent={
            <Typography variant="h2">
              We partner with these leading insurance brands{' '}
            </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Types of Medicare Special Needs Plans (SNPs)
              </Typography>
              <Typography variant="body">
                There are three types of Special Needs Plans (SNPs) available
                for those eligible. However, at Healthcareplans.com, we offer
                only one of the three types: the Dual Eligible Special Needs
                Plan, or D-SNP.
              </Typography>
            </>
          }
          alignMainContent="center"
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns>
              <Column backgroundColor="dark" className="card-padding">
                <StaticImage
                  src="../../images/illustrations/medicare-cards.svg"
                  alt="medicare cards icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">
                  Dual Eligibility SNPs (D-SNPs)
                </Typography>
                <Typography variant="body">
                  Dual Eligible Special Needs Plans are available to those
                  eligible for both Medicare and Medicaid. D-SNPs contract with
                  state Medicaid programs to coordinate Medicare and Medicaid
                  benefits and provide equivalent coverage to the Original
                  Medicare benefits of most Medicare Advantage plans. They also
                  provide additional benefits that may include a monthly
                  stipend, prescription drug coverage, dental coverage, and/or
                  medical transportation costs. D-SNPs usually have low or $0
                  monthly plan premiums.
                </Typography>
                <LinkButton
                  variant=""
                  to="/plans/snp/dsnp"
                  color="white"
                  outlined
                >
                  Learn More About D-SNPs
                </LinkButton>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/illustrations/heart-pieces.svg"
                  alt="heart icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">
                  Chronic Condition SNPs (C-SNPs)
                </Typography>
                <Typography variant="body">
                  Chronic Condition Special Needs Plans (C-SNPs) are available
                  for those eligible for Original Medicare (Medicare Parts A &
                  B) who have one or more eligible severe or disabling chronic
                  conditions. See the comprehensive list below for eligible
                  conditions.
                </Typography>
                <Link to="https://www.cms.gov/medicare/enrollment-renewal/special-needs-plans/institutional">
                  For more information or to find a carrier providing C-SNPs,
                  check out CMS.gov.
                </Link>
              </Column>
              <Column backgroundColor="light" className="card-padding">
                <StaticImage
                  src="../../images/illustrations/nurse.svg"
                  alt="heart icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">
                  Institutional SNPs (I-SNPs)
                </Typography>
                <Typography variant="body">
                  Institutional Special Needs Plans (I-SNPs) are available for
                  those eligible for Original Medicare (Medicare Parts A & B)
                  who need the level of care a professional facility offers, or
                  live/are expected to live for at least 90 days in a
                  residential health care facility.
                </Typography>
                <Link to="https://www.cms.gov/medicare/enrollment-renewal/special-needs-plans/institutional">
                  For more information or to find a carrier providing I-SNPs,
                  check out CMS.gov.
                </Link>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">
                Compare Medicare SNP coverage
              </Typography>
            </>
          }
          alignMainContent="center"
          style={{
            color: '#1b0b54',
          }}
        >
          <div className="comparison-table bg-white">
            <table className="table-main">
              <thead>
                <td>Coverage</td>
                <td>D-SNP</td>
                <td>C-SNP</td>
                <td>I-SNP</td>
              </thead>
              <tbody>
                <tr>
                  <td>Charges monthly premium</td>
                  <td>Some plans</td>
                  <td>Some plans</td>
                  <td>Some plans</td>
                </tr>
                <tr>
                  <td>Offers prescription drug (Part D) coverage</td>
                  <td>Yes</td>
                  <td>Yes</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Requires primary care doctor</td>
                  <td>Some plans</td>
                  <td>Some plans</td>
                  <td>Some plans</td>
                </tr>
                <tr>
                  <td>Requires specialist referrals</td>
                  <td>Some plans</td>
                  <td>Some plans</td>
                  <td>Some plans</td>
                </tr>
                <tr>
                  <td>Offers out-of-network coverage</td>
                  <td>Some plans</td>
                  <td>Some plans</td>
                  <td>Some plans</td>
                </tr>
              </tbody>
            </table>
          </div>
        </VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <>
              <Typography variant="h2">
                Who is eligible for a Medicare SNP?
              </Typography>
              <Typography variant="body">
                Each Medicare Special Needs Plan has different eligibility
                requirements. However, for any Special Needs Plan, you must have
                Medicare Part A (Hospital Insurance) and Medicare Part B
                (Medical Insurance), and live in the plan’s service area.
              </Typography>
              <Typography variant="h4">
                D-SNP eligibility requirements:
              </Typography>
              <Typography variant="body">
                For a Dual Eligible Special Needs Plan (D-SNP), you must qualify
                for both Medicare and Medicaid.
              </Typography>
              <List>
                <ListItem>
                  Medicare eligibility:
                  <List>
                    <ListItem>
                      <strong>By age:</strong> If you are a United States
                      citizen who has been living in the U.S. for the past 5
                      years and are 65 years of age (or nearing your 65th
                      birthday), you are eligible for Original Medicare (Parts A
                      & B).
                    </ListItem>
                    <ListItem>
                      <strong>By special circumstance:</strong> Some
                      disabilities and conditions may qualify you for Medicare
                      coverage before age 65:
                      <List>
                        <ListItem>
                          If you are disabled and have received Social Security
                          benefits for at least 24 consecutive months
                        </ListItem>
                        <ListItem>
                          If you suffer from kidney failure, also known as
                          End-Stage Renal Disease (ESRD), and have had a kidney
                          transplant or been on dialysis for 3 months
                        </ListItem>
                        <ListItem>
                          If you have amyotrophic lateral sclerosis (ALS, also
                          known as Lou Gehrig’s disease)
                        </ListItem>
                      </List>
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  Medicaid eligibility:
                  <List>
                    <ListItem>
                      If you are eligible under one of the following Medicaid
                      categories, you qualify for a D-SNP:
                      <List>
                        <ListItem>Full Medicaid</ListItem>
                        <ListItem>
                          Qualified Medicare Beneficiary (QMB) without other
                          Medicaid
                        </ListItem>
                        <ListItem>QMB Plus</ListItem>
                        <ListItem>
                          Specified Low-income Medicare Beneficiary without
                          other Medicaid (SLMB)
                        </ListItem>
                        <ListItem>SLMB Plus</ListItem>
                        <ListItem>Qualifying Individual (QI)</ListItem>
                        <ListItem>
                          Qualified Disabled and Working Individual (QDWI)
                        </ListItem>
                      </List>
                    </ListItem>
                  </List>
                </ListItem>
              </List>
              <Typography variant="h4">
                C-SNP eligibility requirements:
              </Typography>
              <Typography variant="body">
                To qualify for a Chronic Condition SNP (C-SNP) you must have one
                or more of the following severe or disabling chronic conditions:
              </Typography>
              <List>
                <ListItem>Chronic alcohol dependence</ListItem>
                <ListItem>Chronic drug dependence</ListItem>
                <ListItem>Certain autoimmune disorders</ListItem>
                <ListItem>
                  Cancer (excluding preexisting cancer conditions)
                </ListItem>
                <ListItem>Certain cardiovascular disorders</ListItem>
                <ListItem>Chronic heart failure</ListItem>
                <ListItem>Dementia</ListItem>
                <ListItem>Diabetes mellitus</ListItem>
                <ListItem>End-stage liver disease</ListItem>
                <ListItem>
                  End-Stage Renal Disease (ESRD) requiring dialysis
                </ListItem>
                <ListItem>Certain severe hematologic disorders</ListItem>
                <ListItem>HIV/AIDS</ListItem>
                <ListItem>Certain chronic lung disorders</ListItem>
                <ListItem>
                  Certain chronic and disabling mental health conditions
                </ListItem>
                <ListItem>Certain neurologic disorders</ListItem>
                <ListItem>Stroke</ListItem>
              </List>
              <Typography variant="h4">
                I-SNP eligibility requirements:
              </Typography>
              <Typography variant="body">
                To qualify for an Institutional Special Needs Plan (I-SNP) you
                must live or expect to live for at least 90 days in a
                residential health care facility such as the following.
              </Typography>
              <List>
                <ListItem>Nursing home</ListItem>
                <ListItem>Intermediate care facility</ListItem>
                <ListItem>Rehabilitation hospital</ListItem>
                <ListItem>Long-term care hospital</ListItem>
                <ListItem>Swing-bed hospital</ListItem>
                <ListItem>Psychiatric hospital</ListItem>
              </List>
            </>
          }
          style={{
            color: '#1b0b54',
          }}
        ></VariableContent>

        <a
          href="https://www.trustpilot.com/review/clearlinkinsurance.com"
          target="_blank"
        >
          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <span className="show-on-desktop">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-desktop.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
                <span className="show-on-mobile">
                  <StaticImage
                    src="../../images/icons/trustpilot-reviews-mobile.svg"
                    alt="TrustPilot reviews icon"
                    layout="constrained"
                  />
                </span>
              </>
            }
          />
        </a>

        <VariableContent
          backgroundColor="primary"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">Give us a call</Typography>
              <Typography variant="body">
                Our licensed agents are available Monday through Friday from
                6:00 am to 5:00 pm MT.
              </Typography>
              <LinkButton
                variant="feature"
                to={`tel:${rotatedNumber}`}
                color="white"
                className=""
                requestId={requestId}
              >
                <span className="text-primary">
                  Call {rotatedNumber} <br />
                  TTY 711
                </span>
              </LinkButton>
            </>
          }
        ></VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Medicare Special Needs Plans
            </Typography>
          }
          className="faq-section-padding"
          style={{ color: '#1B0B54' }}
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title: 'How do I enroll in a SNP?',
                  content: (
                    <>
                      <Typography>
                        If you are eligible for a Special Needs Plan, research
                        plans online and shop during a qualifying enrollment
                        period. At Healthcareplans.com, you can use our tools to
                        shop for Dual Eligible Special Needs Plans only; to find
                        resources for C-SNP and I-SNP carriers, visit{' '}
                        <a href="https://www.cms.gov/">CMS.gov</a>.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'When do I enroll in a SNP?',
                  content: (
                    <>
                      <Typography>
                        If you are qualified for a Special Needs Plan, enroll
                        during the following qualified{' '}
                        <a href="/resources/medicare-enrollment-101/">
                          Medicare Enrollment Periods
                        </a>
                        :
                      </Typography>
                      <List>
                        <ListItem>
                          <a href="/resources/when-is-medicare-advantage-annual-enrollment/">
                            Annual Enrollment Period
                          </a>{' '}
                          - October 15–December 7 each year, available to anyone
                          Medicare-eligible. You can enroll in, drop, or switch
                          Medicare Special Needs Plans during this period.
                        </ListItem>
                        <ListItem>
                          Initial Enrollment Period - If you are eligible for a
                          Special Needs Plan, you can enroll during IEP, which
                          begins three months before you turn 65, includes the
                          month you turn 65, and ends three months after your
                          65th birthday month. 
                        </ListItem>
                        <ListItem>
                          D-SNP Special Enrollment Periods - Special Enrollment
                          Periods for Dual Eligible Special Needs Plans differ
                          from those associated with other plan changes. Since
                          2019, those who qualify for a D-SNP can use a Special
                          Enrollment Period to change their D-SNP once every
                          three months for the first nine months of the year:
                        </ListItem>
                        <List className="padding-left">
                          <ListItem>January 1–March 31</ListItem>
                          <ListItem>April 1–June 30</ListItem>
                          <ListItem>July 1–September 30</ListItem>
                        </List>
                        <ListItem>
                          C-SNP Special Enrollment Periods - You may be eligible
                          for a C-SNP Special Enrollment Period if you are
                          diagnosed with one of the qualifying conditions listed
                          above.
                        </ListItem>
                        <ListItem>
                          I-SNP Special Enrollment Periods - You may be eligible
                          for an I-SNP Special Enrollment Period if you are
                          entering a qualified SNP coverage institution or
                          develop the need for residential-level care
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'Do SNPs charge a monthly premium?',
                  content: (
                    <>
                      <Typography>
                        Special Needs Plan costs vary by carrier and location,
                        but many SNPs feature low or $0 monthly premiums,
                        copays, and deductibles. For D-SNPs specifically,
                        Medicaid benefits from the state generally cover most or
                        all of your out-of-pocket costs.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'Do I need to pick a primary care physician (PCP)?',
                  content: (
                    <>
                      <Typography>
                        Plans from some carriers require you to choose a primary
                        care physician and others do not. It will depend on the
                        carrier and specific plan you choose; be sure to check
                        before you sign up.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'Do I have to get a referral to see a specialist?',
                  content: (
                    <>
                      <Typography>
                        Plans from some carriers require you to have specialist
                        referrals and others do not. It will depend on the
                        carrier and specific plan you choose; be sure to check
                        before you sign up.
                      </Typography>
                    </>
                  ),
                },
                {
                  title:
                    'Can I choose a separate Medicare Advantage (Part C plan) with my SNP?',
                  content: (
                    <>
                      <Typography>
                        The coverage provided by your Special Needs Plan is
                        equivalent to the coverage you’d get with a Medicare
                        Advantage (Part C) plan; there is no need to enroll in a
                        separate plan.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Medicare resources</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../images/people/cute-couple.jpg"
                  alt="cute elderly couple"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/gep-oep-enrollment-periods/"
                    style={{ color: '#1b0b54' }}
                  >
                    General Enrollment and Open Enrollment Periods
                  </a>
                </Typography>
                <Typography variant="body">
                  Learn about the two Medicare enrollment periods that run from
                  January through March.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/grandparents-on-couch-with-grandkids.jpg"
                  alt="elederly couple sit with grandkids on couch and smile"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/when-is-medicare-advantage-annual-enrollment/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Annual Enrollment Period 101
                  </a>
                </Typography>
                <Typography variant="body">
                  When is the Medicare Annual Enrollment Period? Get answers to
                  this and other AEP-related questions.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/biking-on-beach.jpg"
                  alt="an elderly couple rides bicycles on the beach"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/medicare-enrollment-101/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Enrollment Overview
                  </a>
                </Typography>
                <Typography variant="body">
                  Get fast facts about Medicare Enrollment, including when you
                  qualify and when to enroll.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
